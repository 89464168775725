import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python', 'JavaScript', 'C++', 'Django', 'Vue', 'Node.js'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I grew up in Boston, MA, and am currently a student @{' '}
              <a href="https://www.wesleyan.edu/"> Wesleyan University. </a>
            </p>

            <p>
              As far back as I can remember, I've always enjoyed the challenge of puzzles. I started
              drawing mazes in kindergarten, learned to solve Rubik's Cubes &lt; 30s in elementary
              school, spent many hours playing chess on the computer in middle school, and I've
              recently been addicted to Wordle ({' '}
              <em> my avg # of guesses is not very impressive </em>). That led me to my career as a
              software engineer and computer science and physics major, where I'm constantly solving
              puzzles.
            </p>

            <p>
              Fast forwarding to today, I'm currently working at the{' '}
              <a href="https://privacytechlab.org/"> Privacy tech lab </a>. As we are a small team,
              I have a variety of roles. I...
              <ul className="skills-list">
                <li> Write and debug front and backend code</li>
                <li> Create data sets for and help train our ML models</li>
                <li> Design and run our usability study </li>
                <li> Maintain and update our database technology </li>
              </ul>
            </p>

            <p>
              When I'm not in class or coding, I'm likely out doing something active. Depending on
              the season, I like to spend my free time running, hiking, playing soccer, or pick-up
              basketball. If the weather doesn't permit, then I'll be inside cooking pan-fried pesto
              gnocchi or baking my signature dessert,{' '}
              <a href="https://basicswithbabish.co/basicsepisodes/treslechescake">
                {' '}
                tres leches cake.
              </a>
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/justin.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
